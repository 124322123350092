import { css } from "@emotion/css";
import { Box, Grid } from "@mui/material";
import { IOrderDeliveryTimerProps } from "components/interfaces";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ColorHex, CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import { OrderDeliveryStatus } from "utils/enums";

export const OrderDeliveryTimer: React.FC<IOrderDeliveryTimerProps> = (
    props: IOrderDeliveryTimerProps
  ) => {
    const { t } = useTranslation(["orderTranslation"]);
    
    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
      isPlaying: true,
      size: 100,
      strokeWidth: 6,
    };

    const renderTime: (dimension: string, time: number) => any = (
      dimension,
      time
    ): any => {
      return (
        <div className="time-wrapper">
          <div className={css`text-align: center; font-size: 32px;`}>{time}</div>
          <div>{dimension}</div>
        </div>
      );
    };

    const getTimeSeconds = (time: number) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time: number) =>
      ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time: number) =>
      ((time % daySeconds) / hourSeconds) | 0;
    const getRemainingTime: (time: string) => number = (
        time: string
      ): number => {
        const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = moment(time).unix(); // use UNIX timestamp in seconds
        return endTime - startTime;
      };

    const [remainingTime, setRemainingTime] = useState<number>(() => getRemainingTime(props.order.readyDate));

    useEffect(() => {
      setRemainingTime(() => getRemainingTime(props.order.readyDate));
    }, [props.order.readyDate]);
    
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <h3
            className={css`
              margin: 0;
              text-align: center;
              font-weight: normal;
            `}
          >
            {props.order.deliveryStatus ===
              OrderDeliveryStatus.ToBeDelivered && (
              <React.Fragment>
                {t("latestDeliveryTime")}:{" "}
                <strong>{moment(props.order.readyDate).format("HH:mm")}</strong>
              </React.Fragment>
            )}
            {props.order.deliveryStatus === OrderDeliveryStatus.ToBeDelivered &&
              props.order.plannedCalculatedDeliveryDate && (
                <React.Fragment>
                  <br/>
                  {t("expectedDeliveryTime")}:{" "}
                  <strong>
                    {moment(props.order.plannedCalculatedDeliveryDate).format(
                      "HH:mm"
                    )}
                  </strong>
                </React.Fragment>
              )}
            {props.order.deliveryStatus === OrderDeliveryStatus.Delivered && (
              <React.Fragment>
                {t("orderDeliveredInfo")}:{" "}
                <strong>
                  {moment(props.order.realDeliveryDate).format("HH:mm")}
                </strong>
              </React.Fragment>
            )}
            {props.order.deliveryStatus === OrderDeliveryStatus.NoOneOpened && (
              <React.Fragment>
                {t("orderNoOneOpenedInfo")}:{" "}
                <strong>
                  {moment(props.order.realDeliveryDate).format("HH:mm")}
                </strong>
              </React.Fragment>
            )}
          </h3>
        </Grid>
        {props.order.deliveryStatus === OrderDeliveryStatus.ToBeDelivered && (
          <React.Fragment>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box p={1}>
                <CountdownCircleTimer
                  {...timerProps}
                  key={`h${remainingTime}`}
                  // colors="#D14081"
                  colors={(props.location.headerFontColor ?? '#000000') as ColorHex}
                  duration={daySeconds}
                  initialRemainingTime={remainingTime % daySeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat:
                      remainingTime - totalElapsedTime > hourSeconds,
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime(
                        t("hours"),
                        getTimeHours(daySeconds - elapsedTime)
                      )}
                    </span>
                  )}
                </CountdownCircleTimer>
              </Box>
              <Box p={1}>
                <CountdownCircleTimer
                  {...timerProps}
                  key={`m${remainingTime}`}
                  // colors="#EF798A"
                  colors={(props.location.headerFontColor ?? '#000000') as ColorHex}
                  duration={hourSeconds}
                  initialRemainingTime={remainingTime % hourSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat:
                      remainingTime - totalElapsedTime > minuteSeconds,
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime(
                        t("minutes"),
                        getTimeMinutes(hourSeconds - elapsedTime)
                      )}
                    </span>
                  )}
                </CountdownCircleTimer>
              </Box>
              <Box p={1}>
                <CountdownCircleTimer
                  {...timerProps}
                  key={`s${remainingTime}`}
                  // colors="#218380"
                  colors={(props.location.headerFontColor ?? '#000000') as ColorHex}
                  duration={minuteSeconds}
                  initialRemainingTime={remainingTime % minuteSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > 0,
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime(t("seconds"), getTimeSeconds(elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
              </Box>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }