import { css } from "@emotion/css";
import { LocationOn, Phone } from "@mui/icons-material";
import { Button, Grid, Link, Typography } from "@mui/material";
import { IOrderDeliveryAddressProps } from "components/interfaces";
import { useTranslation } from "react-i18next";
import { getFormattedAddress } from "utils/helpers";

export const OrderDeliveryAddress: React.FC<IOrderDeliveryAddressProps> = (
    props: IOrderDeliveryAddressProps
  ) => {

    const { t } = useTranslation(["orderTranslation"]);

    return (
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <LocationOn fontSize="large" />
                </td>
                <td>
                  <Typography
                    variant="caption"
                    display="block"
                    className={css`
                      margin-bottom: -5px !important;
                    `}
                  >
                    {t("deliveryAddress")}
                  </Typography>
                  <span>{getFormattedAddress(props.order)}</span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Typography variant="caption" display="block">
                    {t("wrongAddressQuestion")}
                    <Link
                      href={`tel:${props.location?.publicPhoneNo}`}
                      className={css`
                        text-decoration: none;
                        text-transform: uppercase;
                      `}
                    >
                      {" "}
                      {t("callUs")}
                    </Link>
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  }