import { CircularProgress, Container, Divider, GlobalStyles, Grid } from "@mui/material";
import { IGetOrderDetailsResult } from "api/OrderApi/interfaces";
import { IOrderGetDetailsModel } from "api/OrderApi/interfaces-model";
import { useOrderApi } from "api/OrderApi/order-api";
import { IMapMarker } from "components/interfaces";
import { MapBasic } from "components/map/map-basic";
import { LocationDetails } from "components/order/location-details";
import { LocationLogo } from "components/order/location-logo";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiResultStatus, OrderDeliveryStatus, OrderTrackingActionType } from "utils/enums";
import "leaflet/dist/leaflet.css";
import { OrderDeliveryTimer } from "components/order/order-delivery-timer";
import { LocationAdvert } from "components/order/location-advert";
import { OrderRating } from "components/order/order-rating";
import { useTranslation } from "react-i18next";
import { REFRESH_DATA_INTERVAL_IN_MILISECONDS } from "utils/consts";
import {isMobile} from 'react-device-detect';
import { Footer } from "components/common/footer";
import { OrderDeliveryAddress } from "components/order/order-delivery-address";
import { LocationYouTubeVideo } from "components/order/location-youtube-video";
import {Helmet} from "react-helmet";
import { useOrderTrackingActionApi } from "api/OrderTrackingActionApi/order-tracking-action-api";
import { IOrderTrackingActionAddModel } from "api/OrderTrackingActionApi/interfaces-model";
import i18n from "configurations/i18n";
import { getBrowserLanguage } from "utils/helpers";

export const OrderDetails: () => JSX.Element = (): JSX.Element => {
  const { guid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { getDetails } = useOrderApi();
  const { addAction } = useOrderTrackingActionApi();
  const [orderDetails, setOrderDetails] = useState<IGetOrderDetailsResult>();
  const [orderMarker, setOrderMarker] = useState<IMapMarker>();
  const [locationMarker, setLocationMarker] = useState<IMapMarker>();
  const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timer>();
  
  React.useEffect(() => {
    i18n.changeLanguage(getBrowserLanguage());
  }, []);

  const onOrderRated: () => void = (): void => {
    loadOrderDetails(false);
  };

  const loadOrderDetails: (addAction: boolean) => Promise<void> = async (addAction: boolean): Promise<void> => {
    if (guid) {
      let model: IOrderGetDetailsModel = {
        guid: guid,
      };
      await getDetails(model).then((response) => {
        if (response?.status === ApiResultStatus.Ok) {
          setOrderDetails(response.data);
          if(response.data.order.latitude){
            setOrderMarker({
              latitude: response.data.order.latitude!,
              longitude: response.data.order.longitude!,
              id: response.data.order.id,
            });
          }
          setLocationMarker({
            latitude: response.data.location.latitude!,
            longitude: response.data.location.longitude!,
            id: response.data.location.id
          });

          if(addAction){
            if (
              guid &&
              !window.navigator.userAgent.includes("Google-PageRenderer") &&
              !window.navigator.userAgent.includes("SkypeUriPreview") &&
              !window.navigator.userAgent.includes("facebookexternalhit")
            ) {
              addOrderTrackingAction(OrderTrackingActionType.SiteVisit);
            }
          }
        }
        setIsLoading(false);
      });
    }
  };

  const addOrderTrackingAction: (type: OrderTrackingActionType) => Promise<void> = async (type: OrderTrackingActionType): Promise<void> => {
    await addAction({ guid: guid, type: type } as IOrderTrackingActionAddModel).then(() => {});
  };

  useEffect(() => {
    loadOrderDetails(true)
  }, [guid]);

  useEffect(() => {
    if(orderDetails){
      if(!refreshInterval){
        if(orderDetails.order.deliveryStatus === OrderDeliveryStatus.ToBeDelivered){
          setRefreshInterval(
            setInterval(() => {
              loadOrderDetails(false);
            }, REFRESH_DATA_INTERVAL_IN_MILISECONDS)
          );
        }
      }
      else if(orderDetails.order.deliveryStatus !== OrderDeliveryStatus.ToBeDelivered){
        clearInterval(refreshInterval);
        setRefreshInterval(undefined);
      }
    }
  }, [orderDetails]);

    return (
      <React.Fragment>
        <Helmet>
          <meta
            property="og:url"
            content={window.location.href}
            data-react-helmet="true"
          />
          {/* <meta
            property="og:image"
            content={`${window.location.origin}/sdt-logo.png`}
            data-react-helmet="true"
          /> */}
        </Helmet>
        <Container>
          {isLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 32px)",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {guid && orderDetails && (
            <React.Fragment>
              <GlobalStyles
                styles={{
                  body: {
                    backgroundColor:
                      orderDetails.location.headerBackgroundColor ?? "white",
                    color: orderDetails.location.headerFontColor ?? "black",
                  },
                  hr: {
                    borderColor:
                      (orderDetails.location.headerFontColor ?? "black") +
                      " !important",
                  },
                  a: {
                    color:
                      (orderDetails.location.headerFontColor ?? "black") +
                      " !important",
                    textDecoration: "none !important",
                  },
                  textarea: {
                    color:
                      (orderDetails.location.headerFontColor ?? "black") +
                      " !important",
                    borderColor:
                      (orderDetails.location.headerFontColor ?? "black") +
                      " !important",
                  },
                }}
              />

              <Grid container justifyContent="center" rowSpacing={3}>
                {orderDetails.location.orderTrackingLogoFileUrl && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <LocationLogo
                        logoUrl={orderDetails.location.orderTrackingLogoFileUrl}
                        logoRedirectUrl={
                          orderDetails.location.orderTrackingAdvertRedirectUrl
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  <LocationDetails location={orderDetails.location} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} height={isMobile ? "75vw" : "50vh"}>
                  {locationMarker && (
                    <MapBasic
                      orderMarker={orderMarker}
                      locationMarker={locationMarker}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <OrderDeliveryAddress
                    order={orderDetails.order}
                    location={orderDetails.location}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <OrderDeliveryTimer
                    order={orderDetails.order}
                    location={orderDetails.location}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <OrderRating
                    order={orderDetails.order}
                    location={orderDetails.location}
                    onOrderRated={onOrderRated}
                  />
                </Grid>
                {orderDetails.location.orderTrackingAdvertFileUrl && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <LocationAdvert
                        location={orderDetails.location}
                        order={orderDetails.order}
                      />
                    </Grid>
                  </React.Fragment>
                )}
                {orderDetails.location.orderTrackingYouTubeVideoId && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <LocationYouTubeVideo
                        location={orderDetails.location}
                        guid={guid}
                      />
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {!isLoading && guid && !orderDetails && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 32px)",
              }}
            ></div>
          )}
        </Container>
        <Footer />
      </React.Fragment>
    );
};
