import { css } from "@emotion/css";
import { Phone } from "@mui/icons-material";
import { Button, Grid, Link } from "@mui/material";
import { ILocationDetailsProps } from "components/interfaces";

export const LocationDetails: React.FC<ILocationDetailsProps> = (
    props: ILocationDetailsProps
  ) => {
    return (
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <h2
            className={css`
              margin: 0;
            `}
          >
            {props.location?.name}
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {props.location?.street}
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <span
            className={css`
              white-space: nowrap;
            `}
          >
            {props.location?.zipCode} {props.location?.city}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href={"tel:" + props.location?.publicPhoneNo}
            className={css`
              text-decoration: none;
            `}
          >
            <Button
              variant="outlined"
              startIcon={<Phone />}
              className={css`
                color: ${props.location.headerFontColor ?? "black"} !important;
                border-color: ${props.location.headerFontColor ??
                "black"} !important;
              `}
            >
              {props.location?.publicPhoneNo}
            </Button>
          </Link>
        </Grid>
      </Grid>
    );
  }