import { Grid } from "@mui/material";
import { IOrderTrackingActionAddModel } from "api/OrderTrackingActionApi/interfaces-model";
import { useOrderTrackingActionApi } from "api/OrderTrackingActionApi/order-tracking-action-api";
import { LocationYouTubeVideoProps } from "components/interfaces";
import YouTube, { YouTubeProps } from "react-youtube";
import { OrderTrackingActionType } from "utils/enums";

export const LocationYouTubeVideo: React.FC<LocationYouTubeVideoProps> = (
    props: LocationYouTubeVideoProps
  ) => {

    const { addAction } = useOrderTrackingActionApi();
    
    const addOrderTrackingAction: (type: OrderTrackingActionType) => Promise<void> = async (type: OrderTrackingActionType): Promise<void> => {
      await addAction({ guid: props.guid, type: type } as IOrderTrackingActionAddModel).then(() => {
      });
    };
    
    const opts: YouTubeProps["opts"] = {
      width: Math.min(document.body.clientWidth - 30, 640)
    };
    
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <YouTube
            videoId={props.location.orderTrackingYouTubeVideoId}
            opts={opts}
            onPlay={() => {
              addOrderTrackingAction(OrderTrackingActionType.YouTubeVideoWatch);
            }}
          />
        </Grid>
      </Grid>
    );
  }