import axios, { AxiosInstance } from "axios";
import { SweetAlertResult } from "sweetalert2";
import { IBaseApiInterface } from "./interfaces";
import { useTranslation } from "react-i18next";
import useModalError from "hooks/use-modal-error";
import _ from "lodash";

const envBaseURL: string = process.env.REACT_APP_API_BASE_URL as string

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${envBaseURL}`,
  headers: {
    "Access-Control-Allow-Origin": true,
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Content-Type": "application/json",
    "Content-": "application/json",
  },
});

export function useBaseApi(): IBaseApiInterface {
  const { t } = useTranslation(["commonTranslation"]);
  const showModalError: (errorResult: string) => Promise<SweetAlertResult> =
    useModalError();

  const handleError: (error: any) => void = (error: any): void => {
    if (error.response && error.response.status === 406) {
      showModalError(error.response.data.statusMessage);
    } else {
      showModalError(t("globalError"));
    }
  };

  async function baseGetRequest<T>(
    apiUrl: string
  ): Promise<T> {
    return await axiosInstance
      .get(apiUrl, {
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function baseGetRequestWithModel<modelT extends Object, resultT>(
    apiUrl: string,
    model: modelT
  ): Promise<resultT> {
    let paramsArray: string[] = _.map(model, (value, key) => {
      return `${key}=${value}`;
    });
    let params: string = _.join(paramsArray, "&");
    apiUrl += `?${params}`;

    return baseGetRequest<resultT>(apiUrl);
  }

  async function basePostRequest<modelT, resultT>(
    apiUrl: string,
    model: modelT
  ): Promise<resultT> {
    return await axiosInstance
      .post(apiUrl, JSON.stringify(model), {
        headers: {
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  return {
    baseGetRequest,
    baseGetRequestWithModel,
    basePostRequest,
  };
}
