import { css } from "@emotion/css";
import { Grid, Link } from "@mui/material";
import { ILocationLogoProps } from "components/interfaces";

export const LocationLogo: React.FC<ILocationLogoProps> = (
    props: ILocationLogoProps
  ) => {

    return (
      <Grid container pt={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href={props.logoRedirectUrl ?? "#"}
            target="_blank"
          >
            <img
              src={props.logoUrl}
              alt="logo"
              className={css`
                max-height: 100px;
              `}
            />
          </Link>
        </Grid>
      </Grid>
    );
  }