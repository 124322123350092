import { css } from "@emotion/css";
import { Check, ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Grid, IconButton, Link, Rating, TextField, Typography } from "@mui/material";
import { IOrderSaveClientCommentModel, IOrderSaveRateModel } from "api/OrderApi/interfaces-model";
import { useOrderApi } from "api/OrderApi/order-api";
import { IOrderTrackingActionAddModel } from "api/OrderTrackingActionApi/interfaces-model";
import { useOrderTrackingActionApi } from "api/OrderTrackingActionApi/order-tracking-action-api";
import { IOrderRatingProps } from "components/interfaces";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiResultStatus, OrderDeliveryStatus, OrderTrackingActionType } from "utils/enums";

export const OrderRating: React.FC<IOrderRatingProps> = (
    props: IOrderRatingProps
  ) => {
    const { saveRate, saveClientComment } = useOrderApi();

    const [deliveryTimeRate, setDeliveryTimeRate] = useState<number | null>(
      props.order.deliveryTimeRate
    );

    const [productQualityRate, setProductQualityRate] = useState<number | null>(
      props.order.productQualityRate
    );

    const [serviceQualityRate, setServiceQualityRate] = useState<number | null>(
      props.order.serviceQualityRate
    );

    const [clientComment, setClientComment] = useState<string>(
      props.order.clientComment ?? ''
    );

    const [isRated, setIsRated] = useState<boolean>(props.order.isRated);
    const [isCommented, setIsCommented] = useState<boolean>(!!props.order.clientComment);
    const [isClientSatisfied, setIsClientSatisfied] = useState<boolean>(
      props.order.isClientSatisfied
    );
    const [isThumbDown, setIsThumbDown] = useState<boolean>(props.order.serviceQualityRate != null || props.order.productQualityRate != null || props.order.deliveryTimeRate != null );
    const [isThumbUp, setIsThumbUp] = useState<boolean>(false);

    const { t } = useTranslation(["orderTranslation"]);

    const { addAction } = useOrderTrackingActionApi();

    const addOrderTrackingAction: (
      type: OrderTrackingActionType
    ) => Promise<void> = async (
      type: OrderTrackingActionType
    ): Promise<void> => {
      await addAction({
        guid: props.order.guid,
        type: type,
      } as IOrderTrackingActionAddModel).then(() => {
        document.location.href = props.location.googleMapsUrl;
      });
    };

    const saveOrderRate: () => Promise<void> = async (): Promise<void> => {
      if (deliveryTimeRate && productQualityRate && serviceQualityRate) {
        let model: IOrderSaveRateModel = {
          guid: props.order.guid,
          deliveryTimeRate: deliveryTimeRate,
          productQualityRate: productQualityRate,
          serviceQualityRate: serviceQualityRate,
        };
        await saveRate(model).then((response) => {
          if (response?.status === ApiResultStatus.Ok) {
            if(isThumbUp){
              addOrderTrackingAction(
                OrderTrackingActionType.GoogleMapsClick
              );
            }else{
              setIsRated(true);
              setIsClientSatisfied(
                deliveryTimeRate >= 4 &&
                  productQualityRate >= 4 &&
                  serviceQualityRate >= 4
              );
              props.onOrderRated();
            }
          }
        });
      }
    };

    const saveOrderClientComment: () => Promise<void> = async (): Promise<void> => {
        if (isRated && clientComment.trim()) {
          let model: IOrderSaveClientCommentModel = {
            guid: props.order.guid,
            clientComment: clientComment.trim()
          };
          await saveClientComment(model).then((response) => {
            if (response?.status === ApiResultStatus.Ok) {
              setIsCommented(true);
            }
          });
        }
    };

    useEffect(() => {
      if (!isRated) {
        saveOrderRate();
      }
    }, [deliveryTimeRate, serviceQualityRate, productQualityRate]);

    return (
      <Grid container>
        {props.order.deliveryStatus === OrderDeliveryStatus.Delivered &&
          !isThumbDown && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pb={2}
              >
                <h3
                  className={css`
                    margin: 0;
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {t("areYouSatisfiedQuestion")}
                </h3>
              </Grid>
              <Grid
                item
                xs={3}
                sm={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              ></Grid>
              <Grid
                item
                xs={3}
                sm={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  size="large"
                  className={css`
                    color: ${props.location.headerFontColor ??
                    "black"} !important;
                  `}
                  onClick={() => {
                    setIsThumbDown(true);
                  }}
                >
                  <ThumbDownOutlined
                    fontSize="large"
                    htmlColor={isThumbDown ? "red" : "inherit"}
                  />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={3}
                sm={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  size="large"
                  className={css`
                    color: ${props.location.headerFontColor ??
                    "black"} !important;
                  `}
                  onClick={() => {
                    setIsThumbUp(true);
                    setDeliveryTimeRate(5);
                    setProductQualityRate(5);
                    setServiceQualityRate(5);
                  }}
                >
                  <ThumbUpOutlined
                    fontSize="large"
                    htmlColor={isThumbUp ? "green" : "inherit"}
                  />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={3}
                sm={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              ></Grid>
            </React.Fragment>
          )}

        {props.order.deliveryStatus === OrderDeliveryStatus.Delivered &&
          isThumbDown && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography component="legend">{t("deliveryTime")}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pb={1}
              >
                <Rating
                  name="deliveryTimeRate"
                  value={deliveryTimeRate}
                  size="large"
                  disabled={isRated}
                  onChange={(event, newValue) => {
                    setDeliveryTimeRate(newValue);
                  }}
                  className={css`
                    & .MuiRating-iconEmpty {
                      color: ${props.location.headerFontColor ?? "black"};
                    }
                  `}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography component="legend">
                  {t("productQuality")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pb={1}
              >
                <Rating
                  name="productQualityRate"
                  value={productQualityRate}
                  size="large"
                  disabled={isRated}
                  onChange={(event, newValue) => {
                    setProductQualityRate(newValue);
                  }}
                  className={css`
                    & .MuiRating-iconEmpty {
                      color: ${props.location.headerFontColor ?? "black"};
                    }
                  `}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography component="legend">
                  {t("serviceQuality")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Rating
                  name="serviceQualityRate"
                  value={serviceQualityRate}
                  size="large"
                  disabled={isRated}
                  onChange={(event, newValue) => {
                    setServiceQualityRate(newValue);
                  }}
                  className={css`
                    & .MuiRating-iconEmpty {
                      color: ${props.location.headerFontColor ?? "black"};
                    }
                  `}
                />
              </Grid>
              {isRated && (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pt={1}
                >
                  <Alert
                    severity="success"
                    sx={{
                      color: props.location.headerFontColor ?? "black",
                      backgroundColor: "transparent",
                    }}
                    className={css`
                      & .MuiAlert-icon {
                        color: ${props.location.headerFontColor ??
                        "black"} !important;
                      }
                    `}
                  >
                    <AlertTitle>{t("orderRatedHeader")}</AlertTitle>
                    {isClientSatisfied ? (
                      <span>
                        {t("orderRatedClientSatisfiedInfo")}
                        <Link
                          href="#"
                          onClick={() => {
                            addOrderTrackingAction(
                              OrderTrackingActionType.GoogleMapsClick
                            );
                          }}
                          pl={1}
                        >
                          <u>Google Maps</u>
                        </Link>
                      </span>
                    ) : (
                      t("orderRatedClientNotSatisfiedInfo")
                    )}
                  </Alert>
                </Grid>
              )}
              <Grid item md={3} display="flex"></Grid>
              <Grid item md={6} xs={12} display="flex" mt={1}>
                <TextField
                  multiline
                  fullWidth
                  rows={5}
                  disabled={isCommented}
                  placeholder={t("orderCommentPlaceholder") ?? ""}
                  variant="outlined"
                  value={clientComment}
                  onChange={(e) => {
                    setClientComment(e.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: props.location.headerFontColor ?? "black",
                      },
                      "& fieldset": {
                        borderColor: props.location.headerFontColor ?? "black",
                      },
                      "&:hover fieldset": {
                        borderColor: props.location.headerFontColor ?? "black",
                      },
                      "&.Mui-disabled textarea": {
                        WebkitTextFillColor: "inherit",
                      },
                      "&.Mui-disabled fieldset": {
                        borderColor: props.location.headerFontColor ?? "black",
                      },
                    },
                  }}
                />
              </Grid>
              {!isCommented && (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<Check />}
                      className={css`
                  color: ${
                    props.location.headerFontColor ?? "black"
                  } !important;
                  border-color: ${
                    props.location.headerFontColor ?? "black"
                  } !important;
                  "text-transform": none !important;
                `}
                      onClick={() => {
                        saveOrderClientComment();
                      }}
                    >
                      {t("saveComment")}
                    </Button>
                  </Grid>
                  {!isRated && (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="caption" display="block">
                        {t("rateOrderToAddComment")}
                      </Typography>
                    </Grid>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        {props.order.deliveryStatus !== OrderDeliveryStatus.Delivered && (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Alert
              severity="info"
              sx={{
                color: props.location.headerFontColor ?? "black",
                backgroundColor: "transparent",
              }}
              className={css`
                & .MuiAlert-icon {
                  color: ${props.location.headerFontColor ??
                  "black"} !important;
                }
              `}
            >
              <AlertTitle>{t("orderRateInfoBeforeDeliveredHeader")}</AlertTitle>
              <span
                className={css`
                  white-space: pre-wrap;
                `}
              >
                {t("orderRateInfoBeforeDelivered")}
              </span>
            </Alert>
          </Grid>
        )}
      </Grid>
    );
  }