import { css } from "@emotion/css";
import { Grid, Link } from "@mui/material";
import { IOrderTrackingActionAddModel } from "api/OrderTrackingActionApi/interfaces-model";
import { useOrderTrackingActionApi } from "api/OrderTrackingActionApi/order-tracking-action-api";
import { ILocationAdvertProps } from "components/interfaces";
import { OrderTrackingActionType } from "utils/enums";

export const LocationAdvert: React.FC<ILocationAdvertProps> = (
    props: ILocationAdvertProps
  ) => {

    const { addAction } = useOrderTrackingActionApi();
    
    const addOrderTrackingAction: (type: OrderTrackingActionType) => Promise<void> = async (type: OrderTrackingActionType): Promise<void> => {
      await addAction({
        guid: props.order.guid,
        type: type,
      } as IOrderTrackingActionAddModel).then(() => {});
    };

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href={
              (props.order.isAcquiredClient
                ? props.location.orderTrackingAdvertAcquiredRedirectUrl
                : props.location.orderTrackingAdvertRedirectUrl) ?? "#"
            }
            onClick={() => {
              addOrderTrackingAction(
                props.order.isAcquiredClient
                  ? OrderTrackingActionType.BannerAcquiredClick
                  : OrderTrackingActionType.BannerClick
              );
            }}
            target="_blank"
          >
            <img
              src={
                props.order.isAcquiredClient
                  ? props.location.orderTrackingAdvertAcquiredFileUrl
                  : props.location.orderTrackingAdvertFileUrl
              }
              alt="advert"
              className={css`
                max-height: 200px;
                max-width: 100%;
              `}
            />
          </Link>
        </Grid>
      </Grid>
    );
  }