import Swal, { SweetAlertResult } from "sweetalert2";

const useModalError: () => (
  errorResult: string,
  isHtml?: boolean
) => Promise<SweetAlertResult> = (): ((
  errorResult: string,
  isHtml?: boolean
) => Promise<SweetAlertResult>) => {
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = async (
    errorResult: string,
    isHtml?: boolean
  ): Promise<SweetAlertResult> => {
    return await Swal.fire({
      icon: "error",
      text: !isHtml ? errorResult : undefined,
      html: isHtml ? errorResult : undefined,
    });
  };

  return showModalError;
};

export default useModalError;