import { useBaseApi } from "api/base-api";
import { IResultBase } from "api/interfaces";
import { IOrderTrackingActionAddModel } from "./interfaces-model";
import { IOrderTrackingActionApiInterface } from "./interfaces";

export const useOrderTrackingActionApi: () => IOrderTrackingActionApiInterface = (): IOrderTrackingActionApiInterface => {
  const { basePostRequest } = useBaseApi();

  const addAction: (
    model: IOrderTrackingActionAddModel
  ) => Promise<IResultBase> = async (
    model: IOrderTrackingActionAddModel
  ): Promise<IResultBase> => {
    let url: string = "/OrderTrackingAction/Add";
    return await basePostRequest<IOrderTrackingActionAddModel, IResultBase>(url, model);
  };

  return { addAction };
};
