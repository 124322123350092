import { OrderDetails } from "pages/order-details";
import { Route, Routes } from "react-router-dom";
import { PublicRoutes } from "./routes";

export const RouteApp: () => JSX.Element = (): JSX.Element => {
    return (
      <Routes>
        <Route path={PublicRoutes.orderDetails} element={<OrderDetails />} />
      </Routes>
    );
  };
  