import { Paper } from "@mui/material";
import { IMapMarker, IMapProps } from "components/interfaces";
import { LatLngTuple, Map, curve, Curve, latLng } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import { LocationMarker } from "./location-marker";
import { OrderMarker } from "./order-marker";
import {isMobile} from 'react-device-detect';
import { css } from "@emotion/css";
import '@elfalem/leaflet-curve';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export const MapBasic: React.FC<IMapProps> = (props: IMapProps) => {
    const [map, setMap] = useState<Map | null>(null);
  
    const bounds :  LatLngTuple[] = [
      [props.locationMarker.latitude, props.locationMarker.longitude]];

      if(props.orderMarker){
        bounds.push([props.orderMarker.latitude, props.orderMarker.longitude])
      }

      Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      useEffect(() => {
        if(map && props.orderMarker){
          var latlngs = [];

          var latlng1 = props.locationMarker.longitude < props.orderMarker.longitude 
            ? [props.locationMarker.latitude, props.locationMarker.longitude]
            : [props.orderMarker.latitude, props.orderMarker.longitude],
            latlng2 = props.locationMarker.longitude < props.orderMarker.longitude 
              ? [props.orderMarker.latitude, props.orderMarker.longitude]
              : [props.locationMarker.latitude, props.locationMarker.longitude];

          var offsetX = latlng2[1] - latlng1[1],
            offsetY = latlng2[0] - latlng1[0];

          var r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
            theta = Math.atan2(offsetY, offsetX);

          var thetaOffset = 3.14 / 10;

          var r2 = r / 2 / Math.cos(thetaOffset),
            theta2 = theta + thetaOffset;

          var midpointX = r2 * Math.cos(theta2) + latlng1[1],
            midpointY = r2 * Math.sin(theta2) + latlng1[0];

          var midpointLatLng = [midpointY, midpointX];

          latlngs.push(latlng1, midpointLatLng, latlng2);

          var pathOptions = {
            color: "red",
            dashArray: "10, 10",
            dashOffset: "10",
          };

          var path = curve(
            [
              "M",
              [latlng1[0], latlng1[1]],
              "Q",
              [midpointLatLng[0], midpointLatLng[1]],
              [latlng2[0], latlng2[1]],
            ],
            pathOptions
          ).addTo(map);
          (map as any).gestureHandling.enable();
        }
      }, [map]);

    return (
      <Paper variant="elevation" elevation={3} sx={{ height: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <MapContainer
            // dragging={!isMobile}
            // tap={!isMobile}
            // scrollWheelZoom={false}
            ref={setMap}
            // center={[
            //   props.locationMarker.latitude,
            //   props.locationMarker.longitude,
            // ]}
            // zoom={11}
            style={{ height: "100%", width: "100%" }}
            bounds={bounds}
            boundsOptions={{padding:[10, 10]}}
            // whenCreated={(map) => {
            //   mapRef.current = map;
            // }}
            className={css`& a{ color: black !important }`}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
              // url="https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {props.orderMarker && (
              <React.Fragment>
                <OrderMarker
                  markerData={props.orderMarker} /*mapRef={mapRef}*/
                />
                {/* <Polyline
                  key={`${props.orderMarker.id},${props.locationMarker.id}`}
                  positions={[
                    [
                      props.locationMarker.latitude,
                      props.locationMarker.longitude,
                    ],
                    [props.orderMarker.latitude, props.orderMarker.longitude],
                  ]}
                  pathOptions={{
                    color: "red",
                    dashArray: "10, 10",
                    dashOffset: "10",
                  }}
                /> */}
              </React.Fragment>
            )}
            <LocationMarker
              markerData={props.locationMarker} /*mapRef={mapRef}*/
            />
          </MapContainer>
        </div>
      </Paper>
    );
  };