import { css } from "@emotion/css";
import { Grid } from "@mui/material";
import moment from "moment";

export const Footer: React.FC = (
  props: {}
  ) => {
    return (
      <div
        className={css`
          color: #faa450;
          font-weight: 400;
          text-align: center;
          background-color: black;
          padding-top: 5px;
          padding-bottom: 5px;
        `}
      >
        Smart Delivery Track © {moment().year().toString()}
      </div>
    );
  }