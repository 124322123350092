import { IOrderDto } from "api/OrderApi/interfaces";
import _ from "lodash";
import { OrderTrackingActionType } from "./enums";
import { IOrderTrackingActionAddModel } from "api/OrderTrackingActionApi/interfaces-model";
import { useOrderTrackingActionApi } from "api/OrderTrackingActionApi/order-tracking-action-api";

export const getFormattedAddress: (data: IOrderDto) => string = (
  data: IOrderDto
): string => {
  const appartmentNoText: string = data.apartmentNo
    ? `/${data.apartmentNo}`
    : "";
  const zipCodeText: string = _.trim(_.trim(data.zipCode ?? "", "00-000"));
  const address = `${data.street ?? ""} ${
    data.streetNo ?? "-"
  }${appartmentNoText}, ${data.city}`;
  return address;
};

export const getBrowserLanguage: () => string | undefined = (): string | undefined => {
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return trimmedLocale.split(/-|_/)[0];
  })[0];
};