import { useBaseApi } from "api/base-api";
import { IResultBase } from "api/interfaces";
import { IOrderApiInterface } from "./interfaces";
import {
  IOrderGetDetailsModel, IOrderSaveClientCommentModel, IOrderSaveRateModel,
} from "./interfaces-model";
import {
  IOrderGetDetailsResult,
} from "./interfaces-result";

export const useOrderApi: () => IOrderApiInterface = (): IOrderApiInterface => {
  const { baseGetRequestWithModel, basePostRequest } = useBaseApi();

  const getDetails: (
    model: IOrderGetDetailsModel
  ) => Promise<IOrderGetDetailsResult> = async (
    model: IOrderGetDetailsModel
  ): Promise<IOrderGetDetailsResult> => {
    let url: string = "/Order/GetDetails";
    return await baseGetRequestWithModel<
      IOrderGetDetailsModel,
      IOrderGetDetailsResult
    >(url, model);
  };

  const saveRate: (
    model: IOrderSaveRateModel
  ) => Promise<IResultBase> = async (
    model: IOrderSaveRateModel
  ): Promise<IResultBase> => {
    let url: string = "/Order/SaveRate";
    return await basePostRequest<IOrderSaveRateModel, IResultBase>(url, model);
  };

  const saveClientComment: (
    model: IOrderSaveClientCommentModel
  ) => Promise<IResultBase> = async (
    model: IOrderSaveClientCommentModel
  ): Promise<IResultBase> => {
    let url: string = "/Order/SaveClientComment";
    return await basePostRequest<IOrderSaveClientCommentModel, IResultBase>(url, model);
  };

  return { getDetails, saveRate, saveClientComment };
};
