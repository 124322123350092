import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DivIcon } from "leaflet";
import { MutableRefObject, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Marker } from "react-leaflet";
import { TemplateCoordinates } from "proj4";
import { ILocationMarkerProps } from "components/interfaces";
import React from "react";


export const LocationMarker: React.FC<ILocationMarkerProps> = (
    props: ILocationMarkerProps
  ) => {
    // const markerRef: MutableRefObject<any> = useRef();
    const icon = new DivIcon({
      html: ReactDOMServer.renderToString(
        <FontAwesomeIcon
          icon={faHouse}
          size="2xl"
          fixedWidth={true}
          className="icon-marker"
        />
      ),
      iconAnchor: [15, 24]
    });
    const pointEPSG4326: TemplateCoordinates = [
      props.markerData.longitude,
      props.markerData.latitude,
    ];
  
    return (
      <Marker
        position={[pointEPSG4326[1], pointEPSG4326[0]]}
        key={`${props.markerData.id},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
        icon={icon}
        // ref={markerRef}
      >
      </Marker>
    );
  };